// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.banner {
	width: 100%;
	height: rem(800px) !important;
	background-size: cover !important;
	background: no-repeat center;
  	position: relative;
  	float: left;

	.centered {
		height: 100%;
	}

	@include media($breakpoint-desktop) {
		height: rem(500px)!important;
	}

	&.banner--enjoy {
		height: rem(500)!important;

		@include media($breakpoint-desktop) {
			height: rem(300px)!important;
		}
	}


	&.banner--dealer {
		height: rem(400px)!important;

		h1 {
			@include margin(rem(220px) null null null);
		}
	}

	&.banner--map {
		height: rem(400px)!important;
		position: relative;
			#map {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}
			h1 {
				z-index: 2;
			}
	}

	h1 {

		color: #ffffff;
		font-weight: normal;
		position: relative;
		float: left;
		@include font-size(66px);
		@include margin(rem(220px) null null null);
		@include padding(rem(10px) null null null);

		transform: translate(0, 50%);
		transition: all .8s ease-out;
		opacity: 0;

		&.visible {
			opacity: 1;
			transform: translate(0,0);
		}

		@include media($breakpoint-desktop) {
			@include font-size(30px);
			@include margin(rem(300px) null null null);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #fff;
			@include position(absolute, 0px null null 0px);
		}
	}

	h2 {

		color: #ffffff;
		font-weight: normal;
		position: relative;
		float: left;
		@include font-size(66px);
		@include margin(rem(160px) null null null);
		@include padding(rem(10px) null null null);

		@include media($breakpoint-desktop) {
			@include font-size(30px);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #fff;
			@include position(absolute, 0px null null 0px);
		}
	}

	h3 {
		color: #fff;
		font-style: italic;
		float: left;
		font-weight: normal;
		@include font-size(66px);
		@include margin(rem(200px) null null null);


		@include media($breakpoint-desktop) {
			@include font-size(30px);
			@include margin(rem(130px) null null null);
		}
	}

}

.home .banner h1 {
	position: absolute;
	bottom: rem(120px);

  	@media (min-width: 768px) and (max-width: 979px) {
	  bottom: rem(190px);
	}
}

.about-us{
	.banner{
		&.banner--1 {
			h1 {
				@include margin(rem(490px) null null null);
				@include media($breakpoint-tablet) {
					@include margin(rem(350px) null null null);
				}
			}
		}
		h1 {
			@include margin(rem(319px) null null null)
		}
	}
}
.page-template-template-contact .banner h1{
	text-shadow: 1px 1px 5px rgba(0,0,0,0.6);
	&:before {
		box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
	}
}