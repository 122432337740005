// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */
body .newsletter {
	@include media($breakpoint-desktop) {
		padding-top: 2.473684rem;
	}
}
.about {

	@include padding(rem(80px) null rem(60px) null);
	@extend .clear;
	@include background-image('photo2','jpg', right bottom, no-repeat);

	@include media($breakpoint-desktop-extra-large) {
		@include background-image('photo2','jpg', right bottom, no-repeat);
	}
	@include media($breakpoint-tablet) {
		background: #fff;
	}

	&.about--text {
		background: none;

		.about__content {
			width: calc(100% - 448px);
			@include padding(0px);

			@include media($breakpoint-desktop) {
				width: calc(100% - 200px);
			}

			@include media($breakpoint-tablet) {
				width: 100%;
			}
		}
	}

	h3 {
		color: #d0b790;
		font-weight: normal;
		position: relative;
		float: left;
		width: 448px;
		@include font-size(66px);
		@include padding(rem(19px) null null null);

		@include media($breakpoint-desktop) {
			width: 200px;
			@include font-size(30px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(null null rem(30px) null);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
		}
	}

	.about__content {
		float: left;
		width: 35%;
		background-color: rgba(255,255,255,0.7);
		@include padding(rem(20px));

		@include media($breakpoint-desktop) {
			width: calc(100% - 200px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
		}

	}

	p {
		color: #635e61;
		line-height: rem(35px);
		@include margin(null null 20px null);
	}

	.about__link {
		position: relative;
		display: inline-block;
		@include font-size(20px);
		@include padding(rem(10px) rem(40px) rem(10px) null);
		@include background-image('arrow','png', right center, no-repeat);
		@include margin(rem(50) null null null);

		@include media($breakpoint-phone) {
			color: #000;
			@include background-image('arrow5','png', right center, no-repeat);

		}
		&:before {
			width: 60px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
			@include media($breakpoint-phone) {
				border-color: #000;
			}
		}
	}

}
