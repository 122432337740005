// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

* {
	@include margin(0px);
	@include padding(0px);
}

html,
body {
	font-size: $em-base;
	font-family: $font-family-base;
	background-color: $page-bg;
	height: 100%;
	@include media($breakpoint-tablet) {
		font-size: ceil($em-base * 0.85);
	}
	@include media($breakpoint-phone) {
		font-size: ceil($em-base * 0.75);
	}
}

a {

	text-decoration: $link-decoration-base;
	color: $link-color-base;

	&:link,
	&:visited,
	&:active {
		text-decoration: $link-decoration-base;
		color: $link-color-base;
	}

	&:hover {
		text-decoration: $link-hover-decoration-base;
		color: $link-hover-color-base;
	}

}

.clear {
	@include clearfix;
}

.wrapper {
	padding-top: 104px;

  	@media (min-width: 768px) and (max-width: 979px) {
	  padding-top: 194px;
	}

  	@media (max-width: 767px) {
	  padding-top: 60px;
	}
}

.centered {
	@include calc(padding-left, '(100% - #{$width-base}) / 2');
	@include calc(padding-right, '(100% - #{$width-base}) / 2');

	@include media($breakpoint-desktop-extra-large) {
		@include padding(null rem(100px));
	}
	@include media($breakpoint-desktop-large) {
		@include padding(null rem(60px));
	}
	@include media($breakpoint-desktop) {
		@include padding(null rem(50px));
	}
	@include media($breakpoint-tablet) {
		@include padding(null rem(30px));
	}

	&.page {
		margin-top: 40px;
	}

}

.center {
	width: 100%;
	max-width: $width-base;
	@include margin(0px auto);
	@include position(relative);
	@include clearfix;
	@include media(max-width ($width-base + ( $gutter-side * 2 ))) {
		@include padding(null $gutter-side);
	}
	@include media($breakpoint-tablet) {
		@include padding(null $gutter-side-tablet);
	}
	@include media($breakpoint-phone) {
		@include padding(null $gutter-side-phone);
	}
}

.mm-opened .header .menu-trigger {
	background-position: 0 -50px;
}
.parallax-window {
	z-index: 1;
}

section.about {
  position: relative;
  float: left;
}

.wrapper {
  &> div, &> section {
	position: relative;
	float: left;
	width: 100%;
  }
}