// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.slider {
	width: 100%;
	height: calc(100vh - 104px);
	overflow: hidden;
	position: relative;
	background: #3a3a3a;

  	@include media ($breakpoint-tablet) {
	  height: calc(100vh - 60px);
	}

	.slider__main {
		width: 100%;
		height: 100%;

		.slider__slide {
			width: 100%;
			height: 100%;
			overflow: hidden;
			background: no-repeat center center;
			background-size: contain!important;

			img.slider__slide-img {
			  visibility: hidden;
			}

			.centered {
				position: relative;
				height: 100%;
			}

			.project-info {
				position: absolute;
				top: rem(290px);
				left: rem(125px);
				transform: translate(0, -50%);
				width: rem(450px);
				background-color: #fff;
				@include padding(rem(35px));
				z-index: 2;
				display: none;

				header {
					font-weight: bold;
					font-size: 18px;
					color: #635e61;
					margin-bottom: 20px;
				}
				.project-info__body {
					p {
						font-size: 18px;
						line-height: 1.5;
						color: #635e61;
					}
				}

				.project-info__close {
					width: 25px;
					height: 25px;
					position: absolute;
					top: 30px;
					right: 30px;
					&:before, &:after {
						content: '';
						width: 100%;
						height: 2px;
						background-color: #635e61;
						position: absolute;
						left: 0;
						top: 50%;
					}
					&:before {
						transform: rotate(45deg);
					}
					&:after {
						transform: rotate(-45deg);
					}
				}
				a {
					display: block;
					position: relative;
					color: #d0b790;
					font-size: 18px;
					padding-top: 10px;
					margin-top: 30px;
					&:before {
						content: '';
						width: 80px;
						border-top: 2px solid #d0b790;
						position: absolute;
						top: 0px;
						left: 0px;
					}
					&:after {
						content: '';
						display: inline-block;
						vertical-align: top;
						width: 15px;
						height: 15px;
						margin-left: 30px;
						position: relative;
						background-image: url(../../img/arrow.png);
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}

			.project-info-overlay {
				content: '';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: #000;
				opacity: 0.5;
				z-index: 1;
				display: none;
			}

			img {
				min-height: 100%;
				height: auto!important;
				min-width: 100%;
				width: auto!important;

			}
		}
	}

	.slider__thumbs {
		width: 100%;
		z-index: 1000;
		text-align: center;
		@include position(absolute, null null 0px 0px);

		&.slider__thumbs--projects {
			@include position(absolute, null null 20px 0px);

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -20px;
				width: 100%;
				height: 23px;
				background-color: #d0b790;
			}

			.less {
				display: none;
			}
		}
	}

	.slider__thumbs--projects {
		.slider__icons {
			width: 440px;
			@include background-image('bg3','png', center center, no-repeat);

			@include media($breakpoint-phone) {
			  width: 300px;
			}

			a {
				color: #fff;
			}

		  	.fa {
			  	font-size: 20px;
			}
		}
	}

	.slider__icons {
		width: 363px;
		height: 43px;
		display: inline-block;

		@include background-image('bg2','png', center center, no-repeat);

	  	@include media ($breakpoint-phone) {
		  width: 313px;
		  background-size: contain;
		  margin-bottom: -5px;
		}

		ul {
			text-align: center;
			@include padding(10px 5px);
			li {
				display: inline-block;
				vertical-align: middle;
				@include margin(null 5px);

				&.share {
					color: #fff;
				}
			}
		}

	  	.fa {
		  	color: #fff;
		}
	}

	.slider__holder {
		background-color: #d0b790;
	  	transition: margin-bottom 0.3s linear;
		@include padding(rem(25px));
		div {
			height: 100%;
			cursor: pointer;
			@include margin(null rem(10));
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.slick-prev {
		z-index: 100;
		background-color: transparent;
		text-indent: -9999px;
		width: 20px;
		height: 33px;
		border: none;
		@include background-image('prev','png', center center, no-repeat);
		@include position(absolute, 45% null null 10px);
	}

	.slick-next {
		z-index: 100;
		background-color: transparent;
		text-indent: -9999px;
		width: 20px;
		height: 33px;
		border: none;
		@include background-image('next','png', center center, no-repeat);
		@include position(absolute, 45% 10px null null);
	}

  	&.inspiration-slider {
	  	.slider__main {
		  	//height: calc(100% - 170px);
		}
	}
}
.home .slider{
	height: calc(100vh - 250px);

  	@media (min-width: 480px) and (max-width: 767px) and (orientation:landscape) {
	  	height: calc(100vh - 60px);
	}
}
.slick-slide {
	height: 100%!important;
	background-position: bottom center;
}