// Table of Content
// ===============================================
// # Welcome
// # Settings
// # Frameworks
// # Tools
// # Generic
// # Base
// # Components
// # Objects
// # Fonts
// # Vendors
//
//
// # Welcome
//   This file is used to centralize the imports
// =============================================== */
//
// Most of the following guidelines used to write css:
// http://cssguidelin.es
//
// Bower is used for keeping the used libraries up to date
// more information is available at http://bower.io
//
// Import this file using the following HTML or equivalent:
// <link href="dist/css/main.min.css" media="all" rel="stylesheet" type="text/css" />
//
//
// # Settings
//   Globally-available variables and configs
// =============================================== */
//
@import 'variables';
//
//
// # Frameworks
//   Bourbon, neat
// =============================================== */
//
@import '../../bower_components/bourbon/app/assets/stylesheets/bourbon';
@import '../../bower_components/neat/app/assets/stylesheets/neat';
//
//
// # Tools
//   Useful mixins / functions
// =============================================== */
//
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_addresses.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_alignment.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_blockquotes.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_codes.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_functions.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_lists.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_mixins.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_tables.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/tools/_typography.scss";

//
//
// # Generic
//   Normalize style across browsers
// =============================================== */
//
@import '../../bower_components/normalize-scss/_normalize';
//
//
// # Base
// Resets, grids, wrappers, body, typography and forms
// =============================================== */
//
@import 'font-awesome/font-awesome';
@import 'base/reset';
@import 'base/base';
@import 'base/buttons';
@import 'base/content';
@import 'base/forms';
@import 'base/media';
@import 'base/wrapper';
//
//
// # Components
//   Header, footer, sidebars etc
// =============================================== */
//
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_404.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_about.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_banner.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_collection.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_contact.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_dealer.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_details.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_download.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_ebook.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_footer.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_header.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_main-menu.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_newsletter.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_products.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_slider.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_social.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/components/_supportsection.scss";

//
//
//
// # Plugins
//   Apply theme styling to plugins
// =============================================== */
//

//
//
// # Fonts
//   Libraries used in the theme
// =============================================== */
//
@include font-face("tradegothiclight", "../fonts/tradegothiclight");
@include font-face("tradegothic", "../fonts/tradegothic");
@include font-face("tradegothicbold", "../fonts/tradegothicbold");
//
//
// # Vendors
//   Vendor includes
// =============================================== */
//
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/vendor/mmenu/mmenu.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/vendor/photoswipe/_main-settings.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/vendor/photoswipe/photoswipe-skin.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/vendor/photoswipe/photoswipe.scss";
@import "/Users/collin/Documents/Projects/forzalaqua/wp-content/themes/forzalaqua/src/scss/vendor/slick/slick.scss";


@import 'overrides';