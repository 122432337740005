// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.dealer {

	@include padding(rem(90px) null rem(20px));
	@extend .clear;
	@include background-image('bg1','png', 0 0, repeat);

	&.dealer--inquiry {
		background: none;

		input[type="text"] {
			border-color: #f2ece1;
			width: 100%;
			display: block;
		}

		textarea {
			border-color: #f2ece1;
			width: 100%;
			display: block;
		}

		button {
			color: #d0b790;
			font-weight: bold;
			@include background-image('arrow','png', right center, no-repeat);

			&:before {
				border-color: #d0b790;
			}
		}
	}

	.dealer__title {

		float: left;
		width: 448px;
		@include margin(-40px null null null);

		p {
			color: #635e61;
			line-height: rem(30px);
			@include padding(null rem(30px) rem(10px) null);

			strong {
				color: #d0b790;
			}
		}


		@include media($breakpoint-desktop) {
			width: 200px;

		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(null null rem(30px) null);
		}
	}

	h4 {
		color: #fff;
		font-weight: normal;
		position: relative;
		float: left;
		width: 448px;
		@include font-size(66px);
		@include padding(rem(19px) null null null);

		@include media($breakpoint-desktop) {
			width: 200px;
			@include font-size(30px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(null null rem(30px) null);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #fff;
			@include position(absolute, 0px null null 0px);

		}
	}

	.dealer__content {
		float: left;
		width: calc(100% - 448px);

		@include media($breakpoint-desktop) {
			width: calc(100% - 200px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
		}

	}



}
