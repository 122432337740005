// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

.footer {
	width: 100%;
	background-color: #f6f5f1;
	@extend .clear;
	@include padding(rem(35px) rem(20px));

	.footer__left {
		float: left;

		@include media($breakpoint-desktop) {
			width: 100%;
			text-align: center;
		}

	}

	.footer__right {
		float: right;
		@include margin(null null null null);

		@include media($breakpoint-desktop) {
			width: 100%;
			text-align: center;
		}
	}

	.footer__logo {
		float: left;

		@include media($breakpoint-desktop) {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.footer__nav {
		float: left;
		@include padding(null null null rem(20px));

		@include media($breakpoint-desktop) {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
		#menu-footer-menu {
			display: inline-block;
			&-de {
				display: inline-block;
			}
			&-nl {
				display: inline-block
			}
		}
		li {
			display: inline-block;
			border-right: 1px solid #dddcd8;
			@include font-size(16px);
			@include padding(rem(5px) rem(20px));
			&:last-child {
				border-right: 0;
			}

			@include media($breakpoint-desktop-extra-large) {
				@include padding(rem(5px) rem(5px));
			}

			a {
				color: #635e61;
			}
		}
	}

	.footer__links {
		float: left;

		@include media($breakpoint-desktop) {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}

		li {
			display: inline-block;
			border-right: 1px solid #dddcd8;
			color: #635e61;
			@include font-size(16px);
			@include padding(rem(5px) rem(20px));
			&:last-child {
				border-right: 0;
			}

			@include media($breakpoint-desktop-extra-large) {
				@include padding(rem(5px) rem(5px));
			}

			a {
				color: #635e61;
			}
		}
	}

	.footer__company {
		float: left;
		color: #635e61;
		font-weight: bold;
		text-decoration: none;
		@include padding(null null null rem(20px));
		@include margin(rem(5px) null null null);
		font-family: Georgia, Arial;
		@include font-size(16px);

		@include media($breakpoint-desktop) {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.social {
		@include media($breakpoint-desktop) {
			float: none;
			display: inline-block;
			vertical-align: middle;
		}
	}

}