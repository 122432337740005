// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-typography {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $heading-font-family;
		font-weight: $heading-font-weight;
		line-height: $heading-line-height;
		color: $heading-color;
		@include margin(0px 0px 10px);
	}

	h1 {
		@include font-size($font-size-h1);
	}

	h2 {
		@include font-size($font-size-h2);
	}

	h3 {
		@include font-size($font-size-h3);
	}

	h4 {
		@include margin(null null 5px);
		@include font-size($font-size-h4);
	}

	h5 {
		@include margin(null null 5px);
		@include font-size($font-size-h5);
	}

	h6 {
		@include margin(null null 0px);
		@include font-size($font-size-h6);
	}

	p {
		@include margin(0px 0px $gutter-bottom);
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	dfn {
		@extend strong;
		@extend em;
	}

	sup,
	sub {
		line-height: 0;
	}

}