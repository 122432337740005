.ebook {
	iframe {
		width: 100%;
		height: 100%;
		border: none;
		outline: 0;
	}
}
@include media($breakpoint-phone) {
	.page-template-template-ebook footer.footer {
		display: none;
	}
}