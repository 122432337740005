// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.wrapper .collection {
	position: relative;
  	z-index: 1010;
	@extend .clear;

	h2 {
		color: #d0b790;
		font-weight: normal;
		position: relative;
		float: left;
		width: 448px;
		@include font-size(66px);
		@include padding(rem(19px) null null null);
		@include margin(rem(275px) null null null);

		@include media($breakpoint-desktop) {
			width: 200px;
			@include font-size(30px);
			@include margin(rem(80px) null null null);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
		}
	}

	.collection__content {
		float: left;
		width: calc(100% - 448px);
		@include margin(rem(-150px) null null null);
		@include media($breakpoint-desktop) {
			width: calc(100% - 200px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(rem(20px) null null null);
		}
	}

	.collection__holder {
	  	// Flex = killing IE 9 support
		//flex-wrap: wrap;
		//@include align-items(stretch);
		//@include display(flex);
		//@include flex-direction(row);
		//@include justify-content(flex-start);
	  	position: relative;
	  	float: left;
	  	width: 100%;
	  	height: 100%;
	  	z-index: 1011;
	}

	.collection__col {
		color: #fff;
		background-color: #d0b790;
		@include padding(rem(23px));
		@include span-columns(3 of 6);
		transform: translateY(40px);
		opacity: 0;
		transition: all .8s ease-out;
	  	height: 100%;
	  	position: relative;
	  	z-index: 1012;
		@include media($breakpoint-phone) {
			width: 100%;
			@include margin(null null rem(20px) null);
			@include span-columns(1 of 1);
			@include omega(1n);
		}

		&.visible {
			transform: translateY(0);
			opacity: 1;
		}

		&.collection__col--our {
			background-size: cover;
			@include background-image('photo1','png', center center, no-repeat);

			@include media($breakpoint-phone) {
				min-height: rem(200px);
			}
		}

		span {
			display: inline-block;
			@include font-size(18px);
			@include padding(null rem(30px) null null);
			@include background-image('arrow3','png', right bottom 3px, no-repeat);
		}

	}

	.collection__title {
		min-height: inherit;
		@include font-size(24px);

	}

	p {
		min-height: rem(160px);
		line-height: rem(30px);
	}

	.collection__link {
		display: block;
		color: #fff;
		border-top: 1px solid #fff;
		@include padding(rem(10px) null);
		@include background-image('arrow2','png', right center, no-repeat);
	}

	.collection__watch {
		width: 100%;
		overflow: hidden;
		@include padding(rem(110px) null rem(70px) null);

		@include media($breakpoint-desktop) {
			@include padding(rem(50px) null rem(70px) null);
		}
	}

	.collection__watch__col {
		@include span-columns(3 of 6);
		opacity: 0;
		transition: opacity .8s ease-out;
		&.visible {
			opacity: 1;
		}
		@include media($breakpoint-phone) {
			@include margin(null null rem(30px) null);
			@include span-columns(1 of 1);
		}

		p {
			color: #635e61;
			line-height: rem(35px);
			@include margin(null null rem(40px) null);
		}

		.collection__link1 {
			position: relative;
			display: inline-block;
			@include font-size(20px);
			@include padding(rem(10px) rem(40px) rem(10px) null);
			@include background-image('arrow','png', right center, no-repeat);
			&:before {
				width: 60px;
				content: '';
				border-top: 2px solid #d0b790;
				@include position(absolute, 0px null null 0px);
			}
		}
	}

	ul {
		column-count: 2;
	  	@include media($breakpoint-desktop-large) {
		  column-count: 1;
		}

		li {
			@include margin(null null rem(25px) null);

			a {
				color: #635e61;
			}
		}
	}
}
.navigation {
	@include padding(null null rem(50px) null);
	text-align: center;
	li {
		display: inline;
		margin: auto;
		a {
			display: inline-block;
			@include padding(null rem(10px));
		}
		&.active {
			a {
				color: #000;
				&:hover {
					text-decoration: none;
					cursor: default;
				}
			}
		}
	}
}