// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.download {
	width: 100%;

	.download__block {
		border-bottom: 1px solid #635e61;
		@include padding(rem(80px) null rem(30px));
		&:last-child {
			border-bottom: none;
		}
	}

	h2 {

		color: #d0b790;
		font-weight: normal;
		position: relative;
		@include font-size(66px);
		@include padding(rem(10px) null null null);

		@include media($breakpoint-desktop) {
			@include font-size(30px);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
		}
		&.no-description {
			@include padding(null null rem(60px) null);
		}
	}
	.description {
		@include padding(rem(10px) null rem(60px) null);
		color: #635e61;
	}

	ul {
		overflow: hidden;
		@include margin(null rem(-30px) null null);
		li {
			float: left;
			width: 20%;
			@include padding(null rem(30px) rem(30px) null );

			@include media($breakpoint-desktop-large) {
				width: 25%;
			}

			@include media($breakpoint-desktop) {
				width: 33.33%;
			}

			@include media($breakpoint-tablet) {
				width: 50%;
			}

			@include media($breakpoint-phone) {
				width: 100%;
			}

			a {
				display: block;
				&:hover {
					text-decoration: none;

					.download__icon {
						background-color: #635e61;
					}

					.download__link {
						color: #635e61;
						@include background-image('arrow7','png', right center, no-repeat);
						&:before {
							border-color: #635e61;
						}
					}

				}
			}
		}

		.download__icon {
			width: 100%;
			height: rem(190px);
			background-color: #d0b790;
			text-align: center;
			@include padding(rem(35px) null null null);

			@include media($breakpoint-phone) {
				height: rem(250px);
			}

			img {
				display: inline-block;
				@include margin(null null 15px null);
			}
			span {
				display: block;
				@include font-size(22px);
				color: #fff;
			}
		}
	}

	.download__link {
		position: relative;
		display: inline-block;
		@include font-size(20px);
		@include padding(rem(10px) rem(30px) null null);
		@include background-image('arrow','png', right center, no-repeat);
		@include margin(rem(20) null null null);

		@include media($breakpoint-phone) {
			color: #000;
			@include background-image('arrow5','png', right center, no-repeat);

		}
		&:before {
			width: 60px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
			@include media($breakpoint-phone) {
				border-color: #000;
			}
		}
	}
}
