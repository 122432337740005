// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-lists {

	ul,
	ol {
		@include margin(0px $gutter-base $gutter-bottom);
	}

	li ul,
	li ol {
		@include margin(0px $gutter-base);
	}

	ul {
		list-style: none;
	}
	ul li {
		list-style-type: none;
		@include padding(null null null rem(25px));
		@include background-image('bullet','png', left 8px, no-repeat);
		@include media($breakpoint-phone) {
			@include background-image('bullet','png', left 5px, no-repeat);
		}
	}

	ol,
	ol li {
		list-style-type: decimal;
	}

	dl {

		@include margin(0px 0px $gutter-bottom);

		dt {
			font-weight: bold;
		}

		dd {
			@include margin(null null null $gutter-base);
		}

	}

}