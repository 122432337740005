// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.main-menu:not(.mm-menu) {
	float: right;
	@include padding(rem(10px) null null null);

	@include media($breakpoint-desktop) {
		width: 100%;
		text-align: center;
		@include margin(rem(40px) null null null);
	}

	ul {
		text-align: center;
		width: 100%;
		li {
			display: inline-block;
			border-right: 1px solid #ebeae7;
			@include padding(null rem(25px));
			@include font-size(16px);
			@include media($breakpoint-desktop-extra-large) {
				@include padding(null rem(10px));
			}

			@include media($breakpoint-desktop-large) {
				&:last-child {
					border-right: 0;
				}
			}

			@include media($breakpoint-desktop) {
				@include padding(null rem(7px));
			}
			&.active a{
				color: #d0b790;
			}

		  	&.mobile-only {
			  	display: none;
			}

		}
		a {
			color: #635e61;
			display: block;
			line-height: rem(40px);
			&:hover {
				color: #d0b790;
				text-decoration: none;
			}
		}
	}

	@include media($breakpoint-tablet) {
		display: none;
	}

}
