// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

:focus {
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include margin(0px null null);
}

img {
	display: block;
}

ul,
ol,
li {
	list-style: none;
}

figure {
	@include margin(0px);
}

fieldset {
	border: 0px;
	@include margin(0px);
	@include padding(0px);
}
