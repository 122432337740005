// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

.ratio,
audio,
embed,
iframe,
object,
video {
	@include margin(null null $gutter-bottom);
}

.ratio {

	display: block;
	overflow: hidden;
	height: 0;
	@include padding(0px);
	@include position(relative);

	embed,
	iframe,
	object,
	video {
		height: 100%;
		width: 100%;
		border: 0;
		@include position(absolute, 0px null null 0px);
	}

}

.ratio--16x9 {
	@include padding(null null 56.25%);
}

.ratio--4x3 {
	@include padding(null null 75%);
}