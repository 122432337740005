// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.products {

	@include padding(rem(50px) null);
	@extend .clear;

	ul {
		overflow: hidden;

		&.hidden {
			display: none;
		}

		@include margin(null rem(-5px) null null);
		li {
			float: left;
			width: 33.33%;
			position: relative;
			@include padding(null rem(5px) rem(5px) null);

			@include media($breakpoint-desktop) {
				width: 33.33%;
			}

			@include media($breakpoint-tablet) {
				width: 50%;
			}

			@include media($breakpoint-phone) {
				width: 100%;
			}

			a {
				color: #fff;
			}
		}
		img {
			width: 100%;

		}

		span {
			position: relative;
			display: inline-block;
			color: #fff;
			@include font-size(16px);
			@include padding(rem(10px) rem(30px) rem(10px) null);
			@include margin(null null null rem(-10px) null);
			@include background-image('arrow4','png', right center, no-repeat);
			@include position(absolute, null null rem(20px) rem(30px));
			&:before {
				width: 60px;
				content: '';
				border-top: 2px solid #fff;
				@include position(absolute, 0px null null 0px);
			}
		}
	}

	.products__more {
		border-top: 1px solid #505050;
		text-align: center;
		@include padding(rem(10px) null);
		@include margin(rem(50px) null);

		a {
			color: #515151;
			display: inline-block;
			@include font-size(18px);
			@include padding(null 20px null null);
			@include background-image('arrow6','png', right center, no-repeat);

			&:hover {
				text-decoration: none;
			}

			.less {
				display: none;
			}

			&.active {
				@include background-image('arrow6_up','png', right center, no-repeat);
				.more {
					display: none;
				}
				.less {
					display: inline-block;
				}
			}
		}
	}
}
.wrapper {
	footer.footer {
		position: absolute;
		bottom: 0;

	  	// Apparently the absolute doesn't work on mobile
	  	@media (max-width: 767px) {
		  	float: left;
		  	position: relative;
		}
	}
}
