// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

.content {
	font-family: $font-family-base;
	line-height: $line-height-base;
	color: $text-color-base;
	@include font-size($font-size-base);
	@include clearfix;
	@include base-addresses;
	@include base-alignment;
	@include base-blockquotes;
	@include base-codes;
	@include base-lists;
	@include base-tables;
	@include base-typography;
	@include media($breakpoint-phone) {

		img {

			&.align-left,
			&.alignleft,
			&.align-right,
			&.alignright {
				max-width: 50%;
			}

		}

	}
}