// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.details {
	width: 100%;
	@include clearfix;
	@include padding(rem(50px) null);

	.details__sidebar {
		float: left;
		width: 28%;
		background-color: #f6f5f1;
		@include padding(rem(30px));

		@include media($breakpoint-desktop) {
			width: 100%;
		}

	}

	.details__title {
		position: relative;
		display: inline-block;
		color: #d0b790;
		@include font-size(24px);
		@include padding(rem(10px) null rem(30px) null);
		&:before {
			width: 70px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);
		}
	}

	.details__models {
		@include margin(null null rem(60px) null);
		li {
			color: #635e61;
			@include margin(null null rem(10px) null);
			@include font-size(16px);

			a {
				color: #635e61;
				&:hover {
					color: #d0b790;
					text-decoration: none;
				}
			}

		}
	}

	.details__size {
		overflow: hidden;
		@include margin(null rem(-8px) rem(60px) null);
		figure {
			float: left;
			width: 33.33%;
			@include padding(null rem(8px) rem(8px) null);
			img {
				width: 100%;
			}
			figcaption{
				display: none;
			}
		}

	}

	.details__opportunities {
		p {
			line-height: rem(24px);
			text-align: justify;
			@include margin(null null rem(25px) null);
			@include font-size(16px);
		}
	}

	.details__content {
		float: right;
		width: 68%;

		@include media($breakpoint-desktop) {
			width: 100%;
		}
	}

	.details__item {
		background-color: #d0b790;
		min-height: rem(430px);
		@include position(relative, null null null null);
		@include padding(30px);
		@include margin(null null rem(35px) null);

	  	@include media ($breakpoint-phone) {
		  @include padding(25px);
		}

		p {
			line-height: rem(24px);
			color: #fff;
			@include font-size(16px);
			padding-right: rem(20px);
		}
		div.content {
    		column-count: 2;
			@include margin(null null rem(25px) null);

		  	@include media ($breakpoint-phone) {
			  column-count: 1;
			}
		}
	}
	ul.slick-dots{
		display: block;
		width: 100%;
		text-align: center;
		position: absolute;
		top: 3.125rem;
		left: 0px;
		li{
			display: inline-block;
			vertical-align: middle;
		}
	}

	h1 {
		color: #fff;
		font-weight: normal;
		position: relative;
		display: inline-block;
		@include font-size(66px);
		@include padding(rem(10px) null null null);
		@include margin(null null rem(10px) null);

		@include media($breakpoint-desktop) {
			@include font-size(30px);
		}

		&:before {
			width: 45%;
			content: '';
			border-top: 2px solid #fff;
			@include position(absolute, 0px null null 0px);
		}
	}

	.details__share {
		@include position(absolute, null rem(20px) rem(20px) null);
		.share-icon {
			display: block;
			width: rem(40px);
			height: rem(40px);
			background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU3Ljg4NCA1Ny44ODQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU3Ljg4NCA1Ny44ODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxwYXRoIGQ9Ik00NS45NzUsMzcuMDMxYy0zLjA0OSwwLTUuNzgzLDEuMzE0LTcuNjksMy40bC0xNi41NjYtNy45NjhjMC4zOTYtMS4xMDMsMC42MjItMi4yODIsMC42MjItMy41MjEgICBjMC0xLjIzNy0wLjIyNy0yLjQxOC0wLjYyMi0zLjUyMWwxNi41NjYtNy45NjZjMS45MDcsMi4wODUsNC42NDQsMy40MDIsNy42OSwzLjQwMmM1Ljc1OSwwLDEwLjQyOS00LjY2OSwxMC40MjktMTAuNDI4ICAgQzU2LjQwNCw0LjY2OSw1MS43MzQsMCw0NS45NzUsMGMtNS43NiwwLTEwLjQyOSw0LjY2OS0xMC40MjksMTAuNDI5YzAsMC41NTIsMC4wNTYsMS4wOSwwLjEzOSwxLjYxOWwtMTcuNjM1LDguNDc5ICAgYy0xLjcyNC0xLjI2LTMuODQyLTIuMDEyLTYuMTQxLTIuMDEyYy01Ljc1OSwwLTEwLjQyOCw0LjY2OC0xMC40MjgsMTAuNDI1YzAsNS43NjIsNC42NjksMTAuNDMsMTAuNDI4LDEwLjQzICAgYzIuMjk5LDAsNC40MTctMC43NTIsNi4xNC0yLjAxNGwxNy42MzQsOC40ODFjLTAuMDgyLDAuNTI5LTAuMTM5LDEuMDY3LTAuMTM5LDEuNjE5YzAsNS43Niw0LjY3LDEwLjQyOCwxMC40MywxMC40MjggICBjNS43NTksMCwxMC40MjgtNC42NjgsMTAuNDI4LTEwLjQyOEM1Ni40MDIsNDEuNjk3LDUxLjczNCwzNy4wMzEsNDUuOTc1LDM3LjAzMXoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
			background-size: rem(16px);
			background-repeat: no-repeat;
			background-position: right rem(15px) bottom rem(15px);
			cursor: pointer;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 3;
			transition: background-image .4s ease .3s;
		}
		span.share {
			position: absolute;
			right: rem(50px);
			white-space: nowrap;
			bottom: rem(15px);
			font-size: rem(16px);
			color: #fff;
		}
		ul {
			position: absolute;
			background: #fff;
			right: 0;
			bottom: 0;
			@include padding(rem(20px) rem(50px) rem(25px) rem(40px))
			font-size: rem(16px);
			line-height: rem(29px);
			color: transparent;
			transform: scale(0);
			transform-origin: bottom right;
			transition: transform .4s ease .3s;
			z-index: 2;
			li {
				white-space: nowrap;
				opacity: 0;
				transition: opacity .3s ease 0s;
			}
		}
		&.active {
			.share-icon {
				background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU3Ljg4NCA1Ny44ODQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU3Ljg4NCA1Ny44ODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxwYXRoIGQ9Ik00NS45NzUsMzcuMDMxYy0zLjA0OSwwLTUuNzgzLDEuMzE0LTcuNjksMy40bC0xNi41NjYtNy45NjhjMC4zOTYtMS4xMDMsMC42MjItMi4yODIsMC42MjItMy41MjEgICBjMC0xLjIzNy0wLjIyNy0yLjQxOC0wLjYyMi0zLjUyMWwxNi41NjYtNy45NjZjMS45MDcsMi4wODUsNC42NDQsMy40MDIsNy42OSwzLjQwMmM1Ljc1OSwwLDEwLjQyOS00LjY2OSwxMC40MjktMTAuNDI4ICAgQzU2LjQwNCw0LjY2OSw1MS43MzQsMCw0NS45NzUsMGMtNS43NiwwLTEwLjQyOSw0LjY2OS0xMC40MjksMTAuNDI5YzAsMC41NTIsMC4wNTYsMS4wOSwwLjEzOSwxLjYxOWwtMTcuNjM1LDguNDc5ICAgYy0xLjcyNC0xLjI2LTMuODQyLTIuMDEyLTYuMTQxLTIuMDEyYy01Ljc1OSwwLTEwLjQyOCw0LjY2OC0xMC40MjgsMTAuNDI1YzAsNS43NjIsNC42NjksMTAuNDMsMTAuNDI4LDEwLjQzICAgYzIuMjk5LDAsNC40MTctMC43NTIsNi4xNC0yLjAxNGwxNy42MzQsOC40ODFjLTAuMDgyLDAuNTI5LTAuMTM5LDEuMDY3LTAuMTM5LDEuNjE5YzAsNS43Niw0LjY3LDEwLjQyOCwxMC40MywxMC40MjggICBjNS43NTksMCwxMC40MjgtNC42NjgsMTAuNDI4LTEwLjQyOEM1Ni40MDIsNDEuNjk3LDUxLjczNCwzNy4wMzEsNDUuOTc1LDM3LjAzMXoiIGZpbGw9IiNkMGI3OTAiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
				background-size: rem(16px);
				background-repeat: no-repeat;
				background-position: right rem(15px) bottom rem(15px);
				transition: background-image .4s ease;
			}
			ul {
				transform: scale(1);
				transition: transform .4s ease;
				li {
					opacity: 1;
					transition: opacity .3s ease .3s;
				}
			}
		}
	}

	.details__slider {
		width: 100%;
		position: relative;
	  	float: left;
		ul {
			li.slick-slide {
				height: 634px!important;
				img {
					width: auto;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					margin: auto;
				}
			}
		}

	}

	ul.slick-dots {
		width: 100%;
		text-align: center;
		@include position(absolute);
		li{
			display: inline-block;
			vertical-align: middle;
			button {
				display: inline-block;
				cursor: pointer;
				font-size: 0;
				width: 10px;
				height: 10px;
				padding: 0;
				border: 1px solid #fff;
				border-radius: 50%;
				background-color: transparent;
				@include margin(null 5px );
			}
			&.slick-active button, &:hover button{
				background-color: #fff;
			}
		}
	}

	.details__data {
		width: 70%;
		@include padding(rem(20px));

		@include media($breakpoint-tablet) {
			width: 100%;
		}
	}

	table {
		width: 100%;
		td {
			width: 50%;
			border-bottom: 1px solid #e4e4e4;
			@include padding(10px null);
		}
	}


}
