// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.contact {

	@include padding(null null rem(20px));
	@extend .clear;

	&.contact--inquiry {
		background: none;

		input[type="text"] {
			border-color: #f2ece1;
			width: 100%;
			display: block;
		}

		textarea {
			border-color: #f2ece1;
			width: 100%;
			display: block;
		}

		button {
			color: #d0b790;
			font-weight: bold;
			@include background-image('arrow','png', right center, no-repeat);

			&:before {
				border-color: #d0b790;
			}
		}

        .checkbox {
            float: left;
            span {
                width: 100%;
                float: none;
                display: inline-block;
                vertical-align: top;
                font-size: 15px;
                margin-bottom: 0;
                label {
                    input {
                        width: 15px;
                        height: 15px;
                    }
                    span {
                        width: calc( 100% - 30px );

                    }
                }
            }
        }

        .button-submit {
            margin-top: 0;
        }
	}

	.contact__info {

		float: left;
		width: 550px;
		@include margin(50px null null null);

        .contact-col {
            float: left;
            width: 50%;
            .spacing {
                width: 100%;
                height: rem(40px);
            }
            span {
                margin: 0;
                width: 100%;
            }
    		p {
    			color: #635e61;
    			line-height: rem(30px);
    			@include padding(null rem(30px) rem(10px) null);

    			strong {
    				color: #d0b790;
    			}
                i {
                    font-style: normal;
                    font-size: 18px;
                    color: #d0b790;
                }
    		}
            a {
                color: #635e61;
            }

			.contact_extra {
				margin-top: rem(30px);
				span {
					font-size: rem(14px);
					i {
						font-size: rem(16px);
						@include media(max-width 500px) {
							display: block;
						}
					}
				}
			}

        }

		@include media($breakpoint-desktop) {
			width: 100%;

		}

		@include media($breakpoint-tablet) {
			@include margin(null null rem(30px) null);
		}

        span {
            float: none;
            display: block;
        }
	}

	h4 {
		color: #fff;
		font-weight: normal;
		position: relative;
		float: left;
		width: 448px;
		@include font-size(66px);
		@include padding(rem(10px) null null null);

		@include media($breakpoint-desktop) {
			width: 200px;
			@include font-size(30px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(null null rem(30px) null);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #fff;
			@include position(absolute, 0px null null 0px);

		}
	}

	.contact__content {
		float: left;
		width: calc(100% - 550px);
        @include margin(50px null null null);
		@include media($breakpoint-desktop) {
			width: 100%;
		}
        p {
            color: #635e61;
            line-height: rem(30px);
            @include padding(null rem(30px) rem(10px) null);

            strong {
                color: #d0b790;
            }
        }
	}



}
