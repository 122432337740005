.supportsection {
  background-color: #3e3d3d;
  padding: 3% 5%;
  margin-bottom: 20px;
  color: white;

  &__title {
    color: white;
    font-size: 22.5pt;
    font-family: 'Calibri Light',sans-serif;
  }

  &__listswrapper {
    display: flex;
    flex-wrap: wrap;

    .supportsection__list {
      margin: 0px 35px;
      padding:20px;
      flex: 1 1;


      span {
        display: block;
        font-size: 24px;
        font-weight: 600;
        font-family: 'Calibri Light',sans-serif;
        padding: 0px 0px 10px 0px
      }

      ul  {
        li {
          padding: 5px 0px;
        }
      }
    }
  }
}
