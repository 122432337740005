// Table of Content
// ===============================================
// # Variables
// # Elements */


// # Variables
// =============================================== */

// No local variables available


// # Elements
// =============================================== */

.newsletter {

	height: rem(700px);
	background-size: cover;
	@include padding(rem(180px) null rem(20px));
	@extend .clear;
	@include background-image('banner3','jpg', center bottom, no-repeat);

	@include media($breakpoint-desktop) {
		height: rem(400px);
	}


	.newsletter__title {
		float: left;
		width: 448px;
		@include media($breakpoint-desktop) {
			width: 200px;
		}

		@include media($breakpoint-tablet) {
			width: 100%;
			@include margin(null null rem(30px) null);
		}
	}

	p {
		color: #635e61;
		line-height: rem(25px);
		@include font-size(16px);
	}

	h4 {
		color: #d0b790;
		font-weight: normal;
		position: relative;

		@include font-size(66px);
		@include padding(rem(19px) null null null);

		@include media($breakpoint-desktop) {
			@include font-size(30px);
		}

		&:before {
			width: 120px;
			content: '';
			border-top: 2px solid #d0b790;
			@include position(absolute, 0px null null 0px);

		}
	}

	.newsletter__content {
		float: left;
		width: calc(100% - 448px);
		@include margin(rem(20px) null null null);

		@include media($breakpoint-desktop) {
			width: calc(100% - 200px);
		}

		@include media($breakpoint-tablet) {
			width: 100%;
		}
	}

}
