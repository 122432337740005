// Table of Content
// ===============================================
// # General
// # Typography */

// # General
// =============================================== */

// Proper REM calculation
$em-base: 19px; // Do not change this unless you know what you are doing

// Image Path
$image-path: '../../img' !default;

// Retina suffix
$retina: false !default;
$retina-suffix: '@2x' !default;

// Breakpoints
$breakpoint-desktop-extra-large: max-width 1799px !default;
$breakpoint-desktop-large: max-width 1399px !default;
$breakpoint-desktop: max-width 979px !default;
$breakpoint-tablet: max-width 767px !default;
$breakpoint-phone: max-width 479px !default;
$breakpoint-phone-landscape: max-width 767px orientation landscape;

// Page
$page-bg: #ffffff !default;

// Width
$width-base: 1400px !default; // Mainly used for centering

// Gutters
$gutter-base: 20px !default;
$gutter-top: 0px !default;
$gutter-right: $gutter-base !default;
$gutter-bottom: $gutter-base !default;
$gutter-left: $gutter-base !default;
$gutter-side: $gutter-base !default; // Used when base width is reached
$gutter-side-tablet: ($gutter-base / 2) !default; // Used when base width is reached
$gutter-side-phone: ($gutter-base / 2) !default; // Used when base width is reached

// Colors
$color-error: #d9534f !default;
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;

// # Typography
// =============================================== */

$text-color-base: #635e61 !default;

$link-color-base: #d0b790 !default;
$link-decoration-base: none !default;
$link-hover-color-base: darken($link-color-base, 15%) !default;
$link-hover-decoration-base: underline !default;

$font-family-base: 'tradegothiclight', Arial !default;
$font-size-base: 18px !default;
$font-size-h1: floor(($font-size-base * 2.5)) !default;
$font-size-h2: floor(($font-size-base * 2)) !default;
$font-size-h3: ceil(($font-size-base * 1.5)) !default;
$font-size-h4: ceil(($font-size-base * 1.25)) !default;
$font-size-h5: ceil(($font-size-base * 1)) !default;
$font-size-h6: ceil(($font-size-base * 0.75)) !default;

$line-height-base: 1.4 !default;
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;

$heading-font-family: inherit !default;
$heading-font-weight: 600 !default;
$heading-line-height: 1.1 !default;
$heading-color: inherit !default;

// # custom fonts
// =============================================== */
$font-family-tradegothic: 'tradegothic', Arial;
$font-family-tradegothicbold: 'tradegothicbold', Arial;