// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-codes {

	pre,
	code {
		white-space: pre;
		font: 100% 'andale mono', 'lucida console', monospace;
		line-height: 1.5;
		@include margin($gutter-base 0px);
		@include no-gutter-last-child;
	}

}