// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

.wrapper {
	min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
	@include clearfix;

	&.wrapper--page {
		font-family: $font-family-base;
		line-height: $line-height-base;
		color: $text-color-base;
		background-color: $page-bg;
		@include font-size($font-size-base);
	}

}