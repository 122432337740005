.error404 {
	input {
		&[type="search"]{
			color: #fff;
			background: none;
			border: none;
			border-bottom: 1px solid #fff;
			width: 100%;
			text-align: center;
			font-size: 3rem;
			height: 90px;
			font-weight: 400;
		}
		&[type="submit"]{
			display: none;
		}
		&::-webkit-input-placeholder {
		  color: #fff;
		}
		&:-moz-placeholder {
		  color: #fff;
		}
		&::-moz-placeholder {
		  color: #fff;
		}
		&:-ms-input-placeholder {
		  color: #fff;
		}
	}
	.enter-search {
		text-align: center;
		color: #fff;
	}
}