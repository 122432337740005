// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */

// No local variables available

// # Elements
// =============================================== */

.header {

	width: 100%;
	background-color: #f6f5f1;
	@include padding(rem(20px) null);
	@extend .clear;
	position: fixed;
	top: 0;
	z-index: 1499;

	@include media($breakpoint-tablet) {
		height: 60px;
		@include padding(rem(19px) null);
	}
	@media (max-width: 1130px) {
		.header__showrooms{
			display: none;
		}
	}

	.header__logo {
		float: left;
		@include margin(null null null rem(20px));

		@include media($breakpoint-tablet) {
			img {
				width: 150px;
			}
		}

		@include media($breakpoint-phone) {
			img {
				width: 100px;
			}
		}

	}

	.header__showrooms {
		float: left;
		color: #d0b790;
		@include position(relative, null null null null);
		@include padding(rem(8px) rem(40px) null null);
		@include font-size(18px);
		@include background-image('arrow','png', right center, no-repeat);
		@include margin(rem(15px) null null rem(40px));

		@include media($breakpoint-tablet) {
			margin-left: 0;
			@include position(absolute, rem(70px) null null rem(20px));
		}

		&:before {
			width: 60px;
			content: '';
			border-top: 1px solid #d0b790;
			@include position(absolute, 0px null null 0px);
		}
	}

	.search-holder {
		background-color: rgba(90,90,90,0.94);
		position: fixed;
		top: 104px;
		right: -100%;
		bottom: 0;
		opacity: 1;
		width: 100%;
		height: 100%;
		z-index: 999;
		transition: all 300ms ease-in;
		@include media($breakpoint-tablet) {
			top: 60px;
			right: -200%;
		}
		&.activesearch {
			right: 0;
		}

		.search-form {
			width: 100%;
			display: block;
			margin-top: 20%;
			text-align: center;
			@include media($breakpoint-phone-landscape) {
				margin-top: 3%;
			}
			label {
				max-width: 100%;
				overflow: hidden;
			}

			input {
				-webkit-appearance: none;
				background-color: transparent;
				border: 0;
				border-bottom: 1px solid #fff;
				max-width: 100%;
				overflow: hidden;
				margin: 0px auto;
				width: 840px;
				height: 85px;
				box-sizing: border-box;
				font-weight: normal;
				color: rgba(255,255,255,0.7);
				font-size: 60px;
				line-height: 60px;
				text-align: center;

				&::-webkit-input-placeholder {
				   color: rgba(255,255,255,0.7);
				   font-size: 60px;
				   line-height: 60px;
				   text-align: center;
				}

				&:-moz-placeholder { /* Firefox 18- */
				   color: rgba(255,255,255,0.7);
				   font-size: 60px;
				   line-height: 60px;
				   text-align: center;  
				}

				&::-moz-placeholder {  /* Firefox 19+ */
				   color: rgba(255,255,255,0.7);
				   font-size: 60px;
				   line-height: 60px;
				   text-align: center;  
				}

				&:-ms-input-placeholder {  
				   color: rgba(255,255,255,0.7);
				   font-size: 60px;
				   line-height: 60px;
				   text-align: center;  
				}

				@include media($breakpoint-phone-landscape) {
					height: 60px;
					font-size: 30px;
					line-height: 30px;
					&::-webkit-input-placeholder {
					   color: rgba(255,255,255,0.7);
					   font-size: 30px;
					   line-height: 30px;
					   text-align: center;
					}

					&:-moz-placeholder { /* Firefox 18- */
					   color: rgba(255,255,255,0.7);
					   font-size: 30px;
					   line-height: 30px;
					   text-align: center;  
					}

					&::-moz-placeholder {  /* Firefox 19+ */
					   color: rgba(255,255,255,0.7);
					   font-size: 30px;
					   line-height: 30px;
					   text-align: center;  
					}

					&:-ms-input-placeholder {  
					   color: rgba(255,255,255,0.7);
					   font-size: 30px;
					   line-height: 30px;
					   text-align: center;  
					}
				}
			}

			input[type=submit] {
				display: none;
			}
		}

		.enter-search {
			text-align: center;
			color: #fff;
			margin-top: 20px;
		}

		.collection-items-search {
			width: 1400px;
			margin: 0px auto;

			@include media($breakpoint-tablet) {
				width: 100%;
			}

			.collection-items-search-holder {

				margin-top: 50px;
				margin-left: -2%;
				margin-right: -2%;

				@include media($breakpoint-phone-landscape) {
					margin-top: 15px;
				}

				.collection-items-search-holder-item {
					padding-right: 2%;
					padding-left: 2%;
					width: 20%;
					float: left;
					@include media($breakpoint-tablet) {
						width: 33.33%;
						&:nth-child(n+4) {
							display: none;
						}
					}
					@include media($breakpoint-phone) {
						width: 50%;
						&:nth-child(n+3) {
							display: none;
						}
					}

					&:hover {
						text-decoration: none;
					}

					.collection-items-search-holder-item__title {
						color: #fff;
						font-size: 20px;
						position: relative;
						margin-top: 20px;
					}

					.collection-items-search-holder-item__title:before {
						content: '';
						position: absolute;
						top: -10px;
						height: 2px;
						width: 40px;
						left: 0;
						background-color: #fff;
					}

					.collection-items-search-holder-item__background {
						background-size: cover;
						width: 100%;
						height: 150px;
						background-position: center center;
						@include media($breakpoint-phone-landscape) {
							height: 90px;
						}
					}
				}

			}
		}
	}

	.menu-trigger {
		width: 60px;
		height: 60px;
		position: absolute;
		display: none;
		top: 0;
		right: 0;
		margin: 0;
		background: #AFAFAF;
		span {
			width: 60%;
			height: 3px;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #FFFFFF;
			&:first-child() {
				margin-top: -11px;
			}
			&:last-child() {
				margin-top: 11px;
			}
		}
		@include media($breakpoint-tablet) {
			display: block;
		}
	}

	.header__icons {
		float: right;
		@include margin(rem(-20px) null);
		position: relative;

		@include media($breakpoint-tablet) {
			@include margin(-17px null);
		}
		@include media($breakpoint-phone) {
			@include margin(-15px null);
		}

		ul {
			li {
				text-transform: uppercase;
				float: left;
				&.languages {
					ul {
						position: absolute;
						left: 0;
						display: none;
						li {
							display: block;
							background: #eee;
							a {
								padding-right: 2.1875rem;
								padding-left: 1.5625rem;
								width: rem(94px);
							}
						}

					  	@include media ($breakpoint-tablet) {
						  left: auto;
						  right: 105.8px;
						  top: 60px;
						}
					}
				}
				a {
					height: rem(104px);
					width: rem(104px);
					line-height: rem(104px);
					display: block;
					@include padding(null rem(25px));

					@include media($breakpoint-tablet) {
						height: rem(60px);
						line-height: rem(60px);
						@include padding(null rem(15px));
					}

					&.language {
						color: #fff;
						text-transform: uppercase;
						background-color: #635e61;
						@include padding(null rem(35px) null rem(25px));
						@include background-image('arrow1','png', right rem(20px) center, no-repeat);
						width: rem(94px);
						&:hover {
							background-color: #534e51;
						}
						&.no-other{
							background-image: none;
							cursor: default;
							text-decoration: none;
							text-align: center;
							@include padding(null rem(25px) null rem(25px));
							&:hover {
								background: #635e61;
							}
						}
						@include media($breakpoint-tablet) {
							width: 60px;
							height: 60px;
							position: absolute;
							top: 0;
							right: 120px;
							line-height: 60px;
							@include background-image('arrow1','png', right rem(12px) center, no-repeat);
							&.no-other{
								background-image: none;
								cursor: default;
								text-decoration: none;
								&:hover {
									background: #635e61;
								}
							}
						}
					}

					&.search {
						background-color: #d0b790;
						transition: all 150ms ease-in;
						text-align: center;
						@include media($breakpoint-tablet){
							position: absolute;
							right: 60px;
							top: 0;
							width: 60px;
							height: 60px;
							text-align: center;
							line-height: 60px;
						}
						img {
							display: inline-block;
							vertical-align: middle;
						}
						&:hover {
							background-color: #bba685;
						}

						&.activesearch-button {
							background-color: rgba(90,90,90,0.94);
						}
					}
				}

			}
		}
	}

}