// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-alignment {

	.align-none,
	.alignnone {
		display: inline;
	}

	.align-center,
	.aligncenter {
		text-align: center;
		@include margin(0px auto);
	}

	.align-left,
	.alignleft {
		float: left;
		@include margin(0px $gutter-base $gutter-bottom 0px);
	}

	.align-right,
	.alignright {
		float: right;
		@include margin(0px 0px $gutter-bottom $gutter-base);
	}

	.wp-caption,
	figure {

		@include margin(0px 0px $gutter-bottom);

		.wp-caption-text,
		figcaption {
			font-style: italic;
			@include margin(5px);
		}

	}

}