// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-addresses {

	address {
		margin: 0 0 $gutter-bottom;
		display: block;
		font-style: italic;
		@include no-gutter-last-child;
	}

}