// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */

// No local variables available

// # Mixins
// =============================================== */

@mixin base-blockquotes {

	blockquote {
		font-style: italic;
		border: 1px solid #dddddd;
		border-radius: 5px;
		@include margin(0px 0px $gutter-bottom);
		@include padding(20px);
		@include no-gutter-last-child;
	}

}